import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import styles from './styles/style.app-maintenance-page.module.sass'

export default () => {
  useEffect(() => {
    document.documentElement.classList.add('h100')
  }, [])

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className={styles.wrap}>
        <div className={styles.box}>
          <div className={styles.logo}/>
          <p className={styles.warning}>Maximus is temporarily unavailable for system maintenance.</p>
          <p className={styles.info}>Please check back soon. Thank you for your patience.</p>
        </div>
      </div>
    </>
  )
}
